import React from 'react';
import { Link } from 'gatsby';
import LandingImageOne from '../components/images/LandingImageTwo';
import BgVideo from '../components/bgVideo';
import Layout from '../layout';
import LandingImageThree from '../components/images/LandingImageThree';
import './style.css';
import BgVideoTestimonial from '../components/bgVideoTestimonial';
import Clients from '../components/clients';
import ItImageTwo from '../components/images/ItImageTwo';
import Quote from '../components/Quotes';
import BookDiscoveryCall from '../components/BookDiscoveryCall';
import Slider from '../components/slider/Slider';

const Landing = () => (
  <Layout>
    <div className="col-md-12 p-0 mx-auto">
      <div className="container-fluid px-0">
        <div className="py-0">
          <BgVideo />
        </div>
      </div>
      <div className="row p-0 vh-80 bg-themeSecondary vh-100 align-items-start m-0">
        <div className="col-md-7 h-100 image-link-hover p-0">
          <Link to="#" className="">
            <div className="card h-100 border-0 rounded-0 text-white">
              <LandingImageThree />
              <div className="card-img-overlay ov">
                <div className="row p-4 h-100">
                  <div className="card align-self-start w-50 align-self-end p-5 rounded shadow-sm  border-0 bg-white">
                    <small className="card-title text-primary text-uppercase font-weight-bold">Casestudy</small>
                    {/* <h3 className="card-title text-black">Mediservices Ltd</h3> */}
                    <p className="card-text case-text text-neutralDark">
                      MediServices uses innovation and technology to improve patient care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <Slider />

        <div className="col-md-5 h-100 image-link-hover p-0">
          <Link to="#" className="">
            <div className="card h-100 border-0 rounded-0 text-white">
              <LandingImageOne />
              <div className="card-img-overlay ov">
                <div className="row p-4 h-100">
                  <div className="card w-75 align-self-end p-5 rounded shadow-sm border-0 bg-white">
                    <small className="card-title text-primary text-uppercase font-weight-bold">Case study</small>
                    {/* <p className="card-title border-primary border-top-0 border-bottom border-right-0 border-left-0 text-muted">Marcote Ltd</p> */}
                    <p className="case-text text-neutralDark">
                      Marcote Ltd is a specialist coatings application company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="container pb-5 min-vh-100">
        <div className="row p-4">
          <p className="text-left col-md-12 text-muted text-uppercase font-weight-bold mt-5 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            What we do
          </p>
          <div className="col-md-12 text-left align-self-center text-left">
            <div className="bg-hover link-what-we-do ">
              <Link className="text-neutralPrimary" to="#">
                <h4 className="p-4 display-2 link-white-hover mt-0">IT and Systems</h4>
              </Link>
            </div>
            <div className="bg-hover link-what-we-do ">
              <Link className="text-neutralPrimary" to="#">
                <h4 className="p-4 display-2 link-white-hover  mt-0">Web Development</h4>
              </Link>
            </div>
            <div className="bg-hover link-what-we-do ">
              <Link className="text-neutralPrimary" to="#">
                <h4 className="p-4 display-2 link-white-hover  mt-0">Digital Design</h4>
              </Link>
            </div>
            <div className="bg-hover link-what-we-do">
              <Link className="text-neutralPrimary" to="#">
                <h4 className="p-4 display-2 link-white-hover mt-0">Integrations</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid min-vh-100 bg-neutralLighter text-center px-0 py-5">
        <div className="row p-5 ">
          <div className="container">
            <p className=" h2 align-self-center font-weight-light text-left text-neutralTertiary">
              Solutions in web development, automation, lead generation, and platform integration. Allowing you to focus
              on running your business whilst we bring you results
            </p>
          </div>
        </div>
      </div>
      <Clients
        clientImage={<ItImageTwo />}
        text="Maximise site productivity, add processes to turn more leads into sales"
      />
      <Quote
        video={<BgVideoTestimonial />}
        name="Andile Siziba"
        job="Director of Neurophysiology | MediServices Healthcare"
        quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
        quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
      />
      <BookDiscoveryCall />
    </div>
  </Layout>
);

export default Landing;
